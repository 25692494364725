<template>
  <div class="list-manual-course">
    <ValidationObserver
      ref="create-manual-observer"
      @submit.prevent="submitForm()"
    >
      <div class="lmc__content">
        <h5>Basic information</h5>
        <div class="lmc__form">
          <ValidationProvider
            name="Course link"
            :rules="{
              required: true,
              regex: /^(?:(?:https?|http):)?\/\/(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
            }"
            v-slot="{ errors }"
          >
            <div class="lmc-create__form">
              <LxpInput
                label="Course link*"
                placeholder="Enter URL or paste"
                v-model="form.course_url"
                :hasError="errors[0] ? true : false"
                :supportText="errors[0] ? errors[0] : ''"
                :prepend="prependData"
                @prepend="fetchMetaData"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            vid="id"
            name="Course key"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="lmc-create__form">
              <LxpInput
                label="Course key*"
                placeholder="Enter or paste course key"
                v-model="form.course_id"
                :hasError="errors[0] ? true : false"
                :supportText="errors[0] ? errors[0] : ''"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Course name"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="lmc-create__form">
              <LxpInput
                label="Course name*"
                v-model="form.name"
                :hasError="errors[0] ? true : false"
                :supportText="errors[0] ? errors[0] : ''"
              />
            </div>
          </ValidationProvider>
          <div class="lmc__form--content">
            <div class="lmc__form--pacing">
              <h6>Course pacing</h6>
              <div class="lmc__form--pacing__type">
                <div class="d-inline-flex">
                  <LxpRadio
                    text="Self-paced"
                    value="self"
                    selectedVal="self"
                    name="course-pacing"
                    id="course-pacing-self"
                    @input="onInput"
                  />
                  <img
                    src="@/assets/images/partner/courses/info.svg"
                    alt="info"
                    v-b-tooltip.hover.left
                    title="Self-paced courses do not have release dates for course content or due dates for assignments. Learners can complete course material at any time before the course end date."
                  />
                </div>
              </div>
              <div class="lmc__form--pacing__type instructor">
                <div class="d-inline-flex">
                  <LxpRadio
                    text="Instructor-led"
                    value="instructor"
                    selectedVal="self"
                    name="course-pacing"
                    id="course-pacing-instructor"
                    @input="onInput"
                  />
                  <img
                    src="@/assets/images/partner/courses/info.svg"
                    alt="info"
                    v-b-tooltip.hover.left
                    title="Instructor-paced courses progress at the pace that the course author sets. Ideally those have configured release dates for course content and due dates for assignments."
                  />
                </div>
              </div>
            </div>
            <div class="lmc__form--language">
              <h6>Course language</h6>
              <ValidationProvider
                name="Language"
                rules="required"
                v-slot="{ errors }"
              >
                <LxpDropdown
                  label="Language*"
                  placeholder="Select Language"
                  :isFloating="true"
                  dropdownClass="lmc__language--dd"
                  :dropdownArr="languageOptions"
                  :showError="errors[0] ? true : false"
                  :supportText="errors[0] ? errors[0] : ''"
                  :value="form.language"
                  @click="r => (form.language = r)"
                ></LxpDropdown>
              </ValidationProvider>
            </div>
          </div>
          <div class="lmc__form--schedule">
            <div class="lmc__form--schedule__heading">
              <h6>Course Schedule</h6>
              <span class="lmc__form--schedule__heading--note"
                >Dates control when your course can be viewed by learners</span
              >
            </div>
            <ValidationProvider
              name="Timezone"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="lmc-create__form">
                <LxpDropdown
                  label="Time zone*"
                  placeholder="Select Timezone"
                  :isFloating="true"
                  dropdownClass="lmc__language--dd"
                  :dropdownArr="timezoneArr"
                  :showError="errors[0] ? true : false"
                  :supportText="errors[0] ? errors[0] : ''"
                  :value="form.timezone"
                  @click="t => (form.timezone = t)"
                ></LxpDropdown>
              </div>
            </ValidationProvider>
            <div class="lmc__form--schedule__div">
              <div class="lmc__form--schedule__div--left">
                <ValidationProvider
                  name="Course start date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="lmc-create__form">
                    <CourseDate
                      id="course-start-date"
                      label="Course start
                    date*"
                      :hasError="errors[0] ? true : false"
                      :supportText="errors[0] ? errors[0] : ''"
                      :value="form.start"
                      :max="form.end !== '' ? form.end : null"
                      @input="e => (form.start = e)"
                    />
                  </div>
                </ValidationProvider>
              </div>
              <div class="lmc__form--schedule__div--right">
                <ValidationProvider name="Course start time">
                  <div class="lmc-create__form">
                    <LxpDropdown
                      label="Course start time"
                      placeholder="Course start time"
                      :isFloating="true"
                      dropdownClass="lmc__language--dd"
                      :dropdownArr="timeArr"
                      :value="date.start_time"
                      @click="t => (date.start_time = t)"
                    ></LxpDropdown>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="lmc__form--schedule__div">
              <div class="lmc__form--schedule__div--left">
                <ValidationProvider
                  name="Course end date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="lmc-create__form">
                    <!-- :min="form.start ? renderDate(form.start) : null" -->

                    <CourseDate
                      id="course-end-date"
                      label="Course end date*"
                      :value="form.end"
                      :hasError="errors[0] ? true : false"
                      :supportText="errors[0] ? errors[0] : ''"
                      :min="form.start ? form.start : null"
                      :max="null"
                      @input="e => (form.end = e)"
                    />
                  </div>
                </ValidationProvider>
              </div>
              <div class="lmc__form--schedule__div--right">
                <ValidationProvider name="Course end time">
                  <div class="lmc-create__form">
                    <LxpDropdown
                      label="Course end time"
                      placeholder="Course end time"
                      :isFloating="true"
                      dropdownClass="lmc__language--dd"
                      :dropdownArr="timeArr"
                      :value="date.end_time"
                      @click="t => (date.end_time = t)"
                    ></LxpDropdown>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="lmc__form--schedule__div">
              <div class="lmc__form--schedule__div--left">
                <ValidationProvider
                  name="Enrollment start date"
                  v-slot="{ errors }"
                >
                  <div class="lmc-create__form">
                    <CourseDate
                      id="enrollment-start-date"
                      label="Enrollment start date"
                      :hasError="errors[0] ? true : false"
                      :supportText="errors[0] ? errors[0] : ''"
                      :max="form.start ? renderDate(form.start) : null"
                      :value="form.enrollment_start"
                      @input="e => (form.enrollment_start = e)"
                    />
                  </div>
                </ValidationProvider>
              </div>
              <div class="lmc__form--schedule__div--right">
                <ValidationProvider name="Enrollment start time">
                  <div class="lmc-create__form">
                    <LxpDropdown
                      label="Enrollment start time"
                      placeholder="Enrollment start time"
                      :isFloating="true"
                      dropdownClass="lmc__language--dd"
                      :dropdownArr="timeArr"
                      :value="date.enrollment_start_time"
                      @click="t => (date.enrollment_start_time = t)"
                    ></LxpDropdown>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="lmc__form--schedule__div">
              <div class="lmc__form--schedule__div--left">
                <ValidationProvider
                  name="Enrollment end date"
                  v-slot="{ errors }"
                >
                  <div class="lmc-create__form">
                    <CourseDate
                      id="enrollment-end-date"
                      label="Enrollment end date"
                      :hasError="errors[0] ? true : false"
                      :supportText="errors[0] ? errors[0] : ''"
                      :value="form.enrollment_end"
                      :min="
                        form.enrollment_start
                          ? renderDate(form.enrollment_start)
                          : null
                      "
                      :max="form.end ? renderDate(form.end) : null"
                      @input="e => (form.enrollment_end = e)"
                    />
                  </div>
                </ValidationProvider>
              </div>
              <div class="lmc__form--schedule__div--right">
                <ValidationProvider name="Enrollment end time">
                  <div class="lmc-create__form">
                    <LxpDropdown
                      label="Enrollment end time"
                      placeholder="Enrollment end time"
                      :isFloating="true"
                      dropdownClass="lmc__language--dd"
                      :dropdownArr="timeArr"
                      :value="date.enrollment_end_time"
                      @click="t => (date.enrollment_end_time = t)"
                    ></LxpDropdown>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lmc__course-overview">
        <h5>Course overview</h5>
        <div class="lmc__form">
          <ValidationProvider
            name="Course Image"
            :rules="{
              required: true,
              regex: /^(?:(?:https?|http):)?\/\/(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
            }"
            v-slot="{ errors }"
          >
            <div class="lmc-create__form">
              <LxpInput
                label="Course Image*"
                placeholder="Enter URL or paste"
                v-model="form.course_image_url"
                :value="form.course_image_url"
                :hasError="errors[0] ? true : false"
                :supportText="errors[0] ? errors[0] : ''"
              />
            </div>
          </ValidationProvider>
          <div class="lmc__form--details">
            <h6>Course Details</h6>
            <div class="lmc-create__form">
              <LxpTextarea
                label="Course short description"
                placeholder="This description appears on the course details page when students choose to click the course card."
                :limit="150"
                :rows="5"
                showLimit
                v-model="form.short_description"
                :value="form.short_description"
              />
            </div>
            <ValidationProvider
              name="About"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="lmc-create__form">
                <LxpTextEditor
                  id="about"
                  class="lxp-editor"
                  label="About this course*"
                  placeholder="This description appears on the course details page when students choose to click the course card."
                  v-model="form.about"
                  :limit="10000"
                  :hasError="errors[0] ? true : false"
                  :supportText="errors[0] ? errors[0] : ''"
                />
              </div>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <LxpButton block variant="secondary1" @click="submitForm()">
        <LxpLoader v-if="isSaveLoading" class="lmc-create__save" />
        <span v-else>Save</span></LxpButton
      >
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import {
  LxpButton,
  LxpInput,
  LxpLoader,
  LxpRadio,
  LxpDropdown,
  LxpTextarea,
  LxpTextEditor
} from "didactica";
import { ValidationObserver } from "vee-validate";
import {
  ValidationProvider,
  extend
} from "vee-validate/dist/vee-validate.full.esm";
import moment from "moment";

import CourseDate from "../Shared/CourseDate.vue";
export default {
  components: {
    LxpButton,
    LxpInput,
    LxpLoader,
    LxpRadio,
    LxpDropdown,
    LxpTextarea,
    LxpTextEditor,
    CourseDate,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      form: {
        course_url: "",
        course_id: "",
        org_short_name: this.userInfo?.partner?.org_short_name,
        name: "",
        pacing: "",
        language: {
          id: "en",
          name: "English"
        },
        course_image_url: "",
        about: "",
        short_description: "",
        start: "",
        end: "",
        enrollment_start: null,
        enrollment_end: null,
        timezone: ""
      },
      date: {
        start_time: "00:00",
        end_time: "00:00",
        enrollment_start_time: "00:00",
        enrollment_end_time: "00:00"
      },
      languageOptions: [],
      timezoneArr: [],
      timeArr: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30"
      ],
      isLinkLoading: false,
      isSaveLoading: false
    };
  },
  computed: {
    ...mapGetters(["userInfo", "allConfig"]),
    prependData() {
      if (this.isLinkLoading) {
        return `<span class="lsf-loader"></span>`;
      } else {
        return `<img src="${require("@/assets/images/partner/courses/sync-blue.svg")}" alt="link" width="24" height="24"/>`;
      }
    }
  },
  mounted() {
    extend("isNotEmpty", {
      validate: value => {
        if (value !== "" && value !== "<p><br></p>" && value !== "\n") {
          return true;
        }
        return false;
      },
      message: "The {_field_} is required."
    });
    this.fetchConstants();
  },
  methods: {
    fetchConstants() {
      axios
        .get(`${this.allConfig.baseURL}/constants?list=timezone,language`)
        .then(res => {
          let data = res.data.data;
          this.languageOptions = data.language.map(l => {
            return {
              id: l.code,
              name: l.language
            };
          });
          this.timezoneArr = data.timezone.map(l => {
            return {
              id: l.id,
              name: l.description
            };
          });
        });
    },
    onInput(e) {
      this.form.pacing = e;
    },
    submitForm() {
      this.$refs["create-manual-observer"].validate().then(res => {
        if (res) {
          this.isSaveLoading = true;
          let formData = JSON.parse(JSON.stringify(this.form));
          formData = this.renderDateTime(formData);
          formData.language = formData.language.id;
          formData.timezone = formData.timezone.id;
          this.$store
            .dispatch("createCourseManually", formData)
            .then(() => {
              this.isSaveLoading = false;
              this.$router.push({ name: "CourseList" });
            })
            .catch(err => {
              this.isSaveLoading = false;
              if (Object.keys(err).includes("non_field_errors")) {
                this.$refs["create-manual-observer"].setErrors({
                  id:
                    "It looks like this course key is already listed on our platform. Please double-check your course listings."
                });
              } else {
                this.$refs["create-manual-observer"].setErrors(err);
              }
            });
        }
      });
    },
    renderDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    renderDateTime(form) {
      if (this.form.start) {
        form.start = moment(this.form.start)
          .set(this.getHourAndMinutes(this.date.start_time))
          .format();
      }
      if (this.form.end) {
        form.end = moment(this.form.end)
          .set(this.getHourAndMinutes(this.date.end_time))
          .format();
      }
      if (this.form.enrollment_start) {
        form.enrollment_start = moment(this.form.enrollment_start)
          .set(this.getHourAndMinutes(this.date.enrollment_start_time))
          .format();
      }
      if (this.form.enrollment_end) {
        form.enrollment_end = moment(this.form.enrollment_end)
          .set(this.getHourAndMinutes(this.date.enrollment_end_time))
          .format();
      }
      return form;
    },
    getHourAndMinutes(time) {
      let cTime = time.split(":");
      return { hour: cTime[0], minute: cTime[1] };
    },
    fetchMetaData() {
      if (
        this.form.course_url &&
        this.$refs["create-manual-observer"].fields["Course link"].valid
      ) {
        let params = {
          url: this.form.course_url
        };
        axios
          .post(`${this.allConfig.baseURL}/url-metadata`, params, {
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`
            }
          })
          .then(res => {
            let data = res.data.data;
            if (data.title) {
              this.form.name = data.title;
            }
            if (data.description) {
              this.form.short_description = data.description;
            }
            if (data.images) {
              this.form.course_image_url = data.images;
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-manual-course {
  .lmc__content,
  .lmc__course-overview {
    @include partner-div-background(24px);
    h5 {
      @include subtitle-regular;
      color: $brand-neutral-900;
      margin-bottom: 24px;
    }
    .lmc__form {
      .lmc-create__form {
        margin-bottom: 16px;
        .lxp-editor {
          height: 484px;
          margin-bottom: 15px;
        }
      }
      .lmc__form--content {
        margin: 24px 0;
        @include horizontal-space-between("flex-start");
        .lmc__form--pacing,
        .lmc__form--language {
          flex: 1;
          h6 {
            @include body-regular(500, $brand-neutral-900);
            margin-bottom: 16px;
          }
          .lmc__form--pacing__type {
            position: relative;
            padding: 8px;
            img {
              margin-left: 10px;
              cursor: pointer;
              &:hover {
                filter: $icon-filter-secondary1;
              }
            }
          }
        }
      }
      .lmc__form--schedule {
        .lmc__form--schedule__heading {
          @include horizontal-space-between;
          margin-bottom: 16px;
          .lmc__form--schedule__heading--note {
            @include label-medium;
            color: $brand-neutral-300;
          }
        }
        .lmc__form--schedule__div {
          @include horizontal-space-between;
          gap: 16px;
          margin-bottom: 8px;

          .lmc__form--schedule__div--left,
          .lmc__form--schedule__div--right {
            display: flex;
            flex: 1;
            span {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.list-manual-course {
  .lmc__form {
    .lx-select__dd--div {
      .placeholder {
        @include body-regular;
        @include text-ellipsis;
        display: inline-flex;
      }
    }
    .lmc-create__form {
      .lxp-editor {
        .quill-editor {
          height: 450px;
          padding-left: 16px;
        }
      }
    }
    .lxp-input {
      .lxp-input__prepend {
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        @keyframes prixClipFix {
          0% {
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
          }
          50% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
          }
          75%,
          100% {
            clip-path: polygon(
              50% 50%,
              0 0,
              100% 0,
              100% 100%,
              100% 100%,
              100% 100%
            );
          }
        }
        .lsf-loader {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          position: relative;
          display: flex;
          animation: rotate 1s linear infinite;
          &::before {
            content: "";
            box-sizing: border-box;
            position: absolute;
            inset: 0px;
            border-radius: 50%;
            border: 3px solid $brand-neutral-300;
            animation: prixClipFix 2s linear infinite;
          }
          &::after {
            content: "";
            box-sizing: border-box;
            position: absolute;
            inset: 0px;
            border-radius: 50%;
            border: 3px solid $brand-neutral-300;
            animation: prixClipFix 2s linear infinite;
            transform: rotate3d(90, 90, 0, 180deg);
            border-color: $brand-primary-400;
          }
        }
      }
    }
  }
  .lmc-create__save {
    min-height: auto;
    .spinner-border {
      color: $brand-neutral-0 !important;
      width: 24px;
      height: 24px;
      border-width: 2px;
    }
  }
}
</style>
